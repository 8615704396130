export function isL2Claim(claimId) {
  return claimId?.length === 11;
}

export function isGuarantorClaim(claimId) {
  return claimId?.length === 7;
}

export function setPaymentBlockingClaims(claim, openClaims) {
  if (claim.oldestUnpaidDueDate && claim.isPaymentAllowed && claim.isPensionFundClaim) {
    claim.paymentBlockingClaims = openClaims
      .filter(
        (y) =>
          y.oldestUnpaidDueDate &&
          y.claimantNumber === claim.claimantNumber &&
          y.claimId !== claim.claimId &&
          y.product === claim.product &&
          y.isPensionFundClaim &&
          !y.isInBookingProcess &&
          y.oldestUnpaidDueDate < claim.oldestUnpaidDueDate
      )
      .sort((a, b) => (a.oldestUnpaidDueDate > b.oldestUnpaidDueDate ? 1 : -1))
      .map((y) => ({ claimId: y.claimId, claimNumber: y.claimNumber, oldestUnpaidDueDate: y.oldestUnpaidDueDate }));
  } else {
    claim.paymentBlockingClaims = [];
  }
  return claim;
}
